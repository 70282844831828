(function module() {
  const wrappers = Array.from(document.querySelectorAll('.new-test-benefit__content'));

  const handleReadMoreLink = (wrapper, link, e) => {
    e.preventDefault();
    const data = JSON.parse(wrapper.querySelector('.new-test-benefit__data').innerHTML);
    const output = wrapper.querySelector('.new-test-benefit__text');

    if (wrapper.classList.contains('full')) {
      output.innerHTML = data.excerpt;
      link.textContent = 'Read More';
    } else {
      output.innerHTML = data.full;
      link.textContent = 'Close';
    }

    wrapper.classList.toggle('full');
  };

  if (wrappers.length) {
    wrappers.map((wrapper) => {
      if (wrapper.dataset.excerpt === 'true') {
        const link = wrapper.querySelector('.read-more-link');
        link.addEventListener('click', e => handleReadMoreLink(wrapper, link, e));
      }
    });
  }
}());

(function module($) {
  const sections = Array.from(document.querySelectorAll('.people__section'));

  const handleMoreClick = (event, person) => {
    event.preventDefault();
    const bio = person.querySelector('.people__section--bio');
    const moreToggle = person.querySelector('.people__section--more');

    bio.classList.toggle('bio-closed');

    if(bio.classList.contains('bio-closed')) {
      moreToggle.innerHTML = 'Read More';
    } else {
      moreToggle.innerHTML = 'Read Less';
    }
  };

  const handlePeople = (person) => {
    const moreToggle = person.querySelector('.people__section--more');

    if(moreToggle) {
      moreToggle.addEventListener('click', (e) => handleMoreClick(e, person));
    }
  };

  const handleSectionClick = (event, section) => {
    event.preventDefault();
    section.classList.toggle('section-closed');
  };

  const handleSection = (section) => {
    const sectionToggle = section.querySelector('.people__section--toggle');
    const sectionPeople = Array.from(section.querySelectorAll('.people__section--person'));

    sectionToggle.addEventListener('click', (e) => handleSectionClick(e, section));

    if(sectionPeople.length) {
      sectionPeople.map(handlePeople);
    }
  };

  if(sections.length) {
    sections.map(handleSection);
  }
}(jQuery));

/* global WOW */
import WOW from 'wow.js/dist/wow.min.js';
import './jquery.selectasaur.js';

(function module($) {
  const ajaxurl = wpData.ajaxurl;
  const answers = Array.from(document.querySelectorAll('.rr-module__quiz--answer'));
  const responses = Array.from(
    document.querySelectorAll('.rr-module__quiz--answer a[data-answer]'),
  );
  const small = document.querySelector('.rr-module__quiz--small');
  const svg = document.querySelector('.rr-module__quiz--pie');
  const introVideo = document.querySelector('.rr-module__intro .video-container video');
  const navToggle = document.querySelector('.rr__navigation--toggle');
  const nav = document.querySelector('.rr__navigation');

  if (nav) {
    const sticky = new window.Waypoint.Sticky({
      element: nav,
    });
  }

  const handleVideo = (video) => {
    const inview = new window.Waypoint.Inview({
      element: video,
      entered() {
        video.play();
      },
      exited() {
        video.pause();
      },
    });
  };

  if (introVideo) {
    handleVideo(introVideo);
  }

  const handleToggle = () => {
    nav.classList.toggle('show');
    const links = Array.from(nav.querySelectorAll('a'));
    if (links.length) {
      links.map(link => link.addEventListener('click', () => nav.classList.remove('show')));
    }
  };

  if (navToggle) {
    navToggle.addEventListener('click', handleToggle);
  }

  new WOW().init({
    callback: () => console.log('initialized'),
  });

  const getPDFs = (grade, postID) => {
    const ptaLink = $('#grade-button-0');
    const scholasticLink = $('#grade-button-1');
    const greatschoolLink = $('#grade-button-2');

    ptaLink.addClass('disabled');
    scholasticLink.addClass('disabled');
    greatschoolLink.addClass('disabled');

    const dataObject = {
      action: 'ajax_get_grade_pdfs',
      grade,
      postID,
    };

    $.ajax({
      method: 'POST',
      url: ajaxurl,
      data: dataObject,
      dataType: 'json',
    })
      .success((response) => {
        ptaLink.attr('href', response.expectations);
        scholasticLink.attr('href', response.grade);
        greatschoolLink.attr('href', response.video);
      })
      .done(() => {
        ptaLink.removeClass('disabled');
        scholasticLink.removeClass('disabled');
        greatschoolLink.removeClass('disabled');
      });
  };

  const selectHandler = (e) => {
    const grade = $(e.target).val();
    const postID = $(e.target).data('post');
    getPDFs(grade, postID);
  };

  const svgHandler = (percent, correct) => {
    /* eslint-disable no-multi-assign, no-unneeded-ternary */
    const wrapper = document.querySelector('.rr-module__quiz--illustration');
    const label = document.querySelector('.rr-module__quiz--pie--label');

    wrapper.className = 'rr-module__quiz--illustration';

    if (svg) {
      const path = svg.querySelector('.rr-module__quiz--pie .rr-module__quiz--pie--outer');
      const percentage = percent ? parseInt(percent, 10) : parseInt(svg.dataset.percent, 10);
      const length = 2 * Math.PI * path.getAttribute('r');

      if (correct) {
        wrapper.classList.add(correct);
      }

      label.innerHTML = `${percentage}%`;
      // Show the path before beginning animation
      path.classList.add('show');
      // Clear any previous transition
      path.style.transition = path.style.WebkitTransition = 'none';
      // Set up the starting positions
      path.style.strokeDasharray = `${length}, ${length}`;
      path.style.strokeDashoffset = length;
      // Trigger a layout so styles are calculated & the browser
      // picks up the starting position before animating
      path.getBoundingClientRect();
      // Define our transition
      path.style.transition = path.style.WebkitTransition = 'stroke-dashoffset 2s ease-in-out';
      // Go!
      path.style.strokeDashoffset = length - length / 100 * percentage;
    }
  };

  const responseHandler = (e, button) => {
    e.preventDefault();
    const correct = button.dataset.correct;
    const parent = button.parentElement;

    /* eslint-disable no-param-reassign */
    answers.map((answer) => {
      answer.className = 'rr-module__quiz--answer';
      const result = answer.querySelector('[data-correct]');
      if (result.dataset.correct === 'correct') {
        answer.classList.add('correct');
        svgHandler(result.dataset.answer, correct);
      }

      return answer;
    });

    small.classList.add('show-alt');
    parent.classList.add('selected');
    parent.classList.add(correct);
  };

  /* event listeners */
  $('#grade-selector')
    .selectasaur()
    .on('change', selectHandler);

  if (svg) {
    window.addEventListener('load', () => svgHandler());
  }

  if (responses) {
    responses.map(response =>
      response.addEventListener('click', e => responseHandler(e, response)),
    );
  }

  /* legacy code */
  /* eslint-disable */
  (function() {
    let e,
      t,
      n = function(e, t) {
        return function() {
          return e.apply(t, arguments);
        };
      };
    (e = (function() {
      function e() {
        (this.updateScroll = n(this.updateScroll, this)),
          (this.compass = $('.charting-compass')),
          (this.bubbles = $('.bubble')),
          (this.checkin = $('.checkin-ctas')),
          (this.bigBubbles = $('.big-bubble')),
          (this.steps = $('.plan-for-college-ctas')),
          (this.copy = $('.charting-copy')),
          (this.infoImg = $('img.infographic')),
          (this.lastScrollY = 0),
          (this.isTicking = !1),
          this.setupEvents();
      }
      return (
        (e.prototype.setupEvents = function() {
          return $(window)
            .on(
              'scroll',
              (function(e) {
                return function() {
                  e.onScroll();
                };
              })(this),
            )
            .on(
              'resize',
              (function(e) {
                return function() {
                  e.onScroll();
                };
              })(this),
            );
        }),
        (e.prototype.onScroll = function() {
          (this.lastScrollY = $(window).scrollTop()),
            typeof requestAnimationFrame !== 'undefined' && requestAnimationFrame !== null
              ? this.requestTick()
              : this.updateScroll();
        }),
        (e.prototype.requestTick = function() {
          this.isTicking || (requestAnimationFrame(this.updateScroll), (this.isTicking = !0));
        }),
        (e.prototype.updateScroll = function() {
          let e, t, n, i, o, r, s, a, l, c, d, u, p, f, h;
          for (
            this.isInview(this.compass),
              this.isInview(this.steps, 2),
              u = this.infoImg,
              n = 0,
              a = u.length;
            a > n;
            n++
          )
            (i = u[n]), this.animateInfoImg(i);
          for (p = this.bigBubbles, o = 0, l = p.length; l > o; o++) (e = p[o]), this.isInview(e);
          for (f = this.bubbles, r = 0, c = f.length; c > r; r++) (e = f[r]), this.isInview(e);
          for (h = this.copy, s = 0, d = h.length; d > s; s++) (t = h[s]), this.animateCopy(t);
          this.isTicking = !1;
        }),
        (e.prototype.animateCopy = function(e) {
          let t, n, i, o, r;
          return (
            (t = $(e)),
            (r = window.innerHeight),
            (i = t.offset().top - r),
            (o = i + r / 1.5),
            (n = this.modulate(this.lastScrollY, [i, o], [60, 0], !0)),
            t.css({
              transform: `translateY(${n.toFixed(2)}px)`,
            })
          );
        }),
        (e.prototype.animateInfoImg = function(e) {
          let t, n, i, o;
          return (
            (o = window.innerHeight),
            (n = $(e).offset().top - o),
            (i = n + o / 1.25),
            (t = this.modulate(this.lastScrollY, [n, i], [60, 0], !0)),
            $(e).css({
              transform: `translateY(${t.toFixed(2)}px)`,
            })
          );
        }),
        (e.prototype.reset = function() {}),
        (e.prototype.modulate = function(e, t, n, i) {
          let o, r, s, a, l;
          if (
            (i == null && (i = !1),
            (r = t[0]),
            (o = t[1]),
            (l = n[0]),
            (a = n[1]),
            (s = l + (e - r) / (o - r) * (a - l)),
            i === !0)
          ) {
            if (a > l) {
              if (l > s) return l;
              if (s > a) return a;
            } else {
              if (s > l) return l;
              if (a > s) return a;
            }
          }
          return s;
        }),
        (e.prototype.isInview = function(e, t) {
          let n, i, o;
          t == null && (t = 1.5),
            (o = $(e).offset().top),
            (n = o + $(e).outerHeight()),
            (i = this.lastScrollY + window.innerHeight / t),
            i > o && this.lastScrollY < n && !$(e).hasClass('inview')
              ? $(e).addClass('inview')
              : o > i && $(e).hasClass('inview') && $(e).removeClass('inview');
        }),
        e
      );
    })()),
      $('.page-template-readiness-roadmap').length && (t = new e());
  })();
}(jQuery));

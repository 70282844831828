import '@fortawesome/fontawesome-free/css/all.css';
import 'chosen-js/chosen.min.css';
import 'slick-carousel/slick/slick.css';
import 'wow.js/css/libs/animate.css';

import './scripts/modules/steps';
import './scripts/modules/sliders';
import './scripts/modules/people';
import './scripts/modules/readiness-roadmap';

import './styles/application.scss'

(function module($) {
  $(document).ready(() => {
    $('.hide-while-loading').removeClass('hide-while-loading');
  });

  $('.menu-toggle').on('click', () => {
    $('.site-nav').toggleClass('expose-menu');
  });

  $('.menu-item a').on('click', () => {
    $('.site-nav').removeClass('expose-menu');
  });

  $('form.newsletter-signup')
    .on('ajax:success', function success(e, t) {
      $(this)
        .removeClass('has-error')
        .find('.newsletter-signup-message')
        .text(t);
      $(this)
        .find('input')
        .val('');
    })
    .on('ajax:error', function error(e, t) {
      $(this)
        .addClass('has-error')
        .find('.newsletter-signup-message')
        .text(t.responseText);
    });

  $('.instructions-trigger').on('click', () => {
    $('.gameplan-instructions').toggleClass('show');
  });

  $('.faq').on('click', '.question, .faq-toggle', function click() {
    $(this)
      .closest('li')
      .toggleClass('faq-opened');
  });

  $('#your-state-form select').selectasaur();
  $('.selectasaur').selectasaur();

  $('#modal-overlay .modal-close').on('click', () => {
    $('#modal-overlay').removeClass('exposed');
  });

  $('#tax_state').on('change', () => {
    let html = '';

    const form = $('#your-state-form');
    const option = $('#tax_state option:selected');
    const link = option.data('link');
    const linkExtra = option.data('link-extra');
    const popup = decodeURI(option.data('popup'));
    const button = option.data('button');
    const buttonExtra = option.data('button-extra');

    if (popup && link) {
      $('.modal-content').html('');

      html = popup;
      html = `${html}<a href="${link}" class="button" target="_blank">${button}&nbsp;»</a>`;

      if (linkExtra) {
        html = `${html}&nbsp;<a href="${linkExtra}" class="button"  target="_blank">${buttonExtra}&nbsp;»</a>`;
      }

      $('.modal-content').html(html);
      $('#modal-overlay').addClass('exposed');
    } else if (link) {
      form.attr('action', link);
      form.attr('method', 'POST');
      form.submit();
    } else if (option.data('group') === 'parcc') {
      form.attr('action', '/classroom/parcc');
      form.attr('method', 'POST');
      form.submit();
    } else if (option.data('group') === 'sbac') {
      form.attr('action', '/classroom/smarter-balanced');
      form.attr('method', 'POST');
      form.submit();
    }
  });

  /* legacy code */
  /* eslint-disable */
  (function() {
    let e, t, n, i, o;

    (i = $('#area optgroup').clone()),
      (e = $('#area')),
      (n = $('#subject')),
      (t = $('#state')),
      (o = function() {
        let o, r, s;
        return (
          (r = t.find(':selected').data('category')),
          (s = n.val() === '' ? null : n.val()),
          (o = i.clone()),
          r != null && o.find(`[data-category!='${r}']`).remove(),
          s != null && (o = o.filter(`.${s}`)),
          e
            .html(o)
            .prepend("<option value=''>Showing All</option>")
            .find('option[value=listening]')
            .attr('disabled', !0)
        );
      }),
      o(),
      t.on('change', () => o()),
      n.on('change', () => o());
  })();

  // testing for clip-paths
  function testClipPaths() {
    const body = document.querySelector('body');

    if (window.areClipPathShapesSupported()) {
      body.classList.remove('no-clip-paths');
    }
  }

  testClipPaths();

  function whatever() {
    const e = $('.slick-dots, .slideshow-nav');

    function onYouTubeIframeAPIReady(e, t) {
      let n;
      return (n = new YT.Player(e, {
        height: '1920',
        width: '1080',
        videoId: t,
        playerVars: {
          rel: 0,
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      }));
    }

    function onPlayerReady(e) {
      return e.target.playVideo();
    }

    function onPlayerStateChange(t) {
      if (
        (t.data === YT.PlayerState.PLAYING &&
          (e.length && e.hide(), $('.slides').length && $('.slides').slick('slickPause')),
        t.data === YT.PlayerState.PAUSED)
      ) {
        if (e.length) return e.show();
      } else if (
        t.data === YT.PlayerState.ENDED &&
        (e.length && e.show(), t.target.destroy(), $('.slides').length)
      )
        return $('.slides').slick('slickPlay');
    }

    $('[data-video-id]').on('click', function() {
      let e, t;
      return (
        (e = $(this).data('player')),
        (t = $(this).data('video-id')),
        onYouTubeIframeAPIReady(e, t),
        !1
      );
    });
  }

  (function() {
    let e, t, n, i;
    (t = $('#contact-form-container')),
      (e = $('.contact-form')),
      (i = e.find('[required]')),
      (n = $('.form-message')),
      $('.contact-form')
        .on('ajax:before', () => {
          let t, o, r;
          return (
            (o = !1),
            (t = !1),
            e.find('button').attr('disabled', !0),
            i.each(function() {
              return (
                $(this).val()
                  ? $(this).removeClass('has-error')
                  : ($(this).addClass('has-error'), (o = !0)),
                $(this).attr('type') !== 'email' ||
                $(this).hasClass('has-error') ||
                $(this)
                  .val()
                  .match(/^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/)
                  ? void 0
                  : ($(this).addClass('has-error'), (t = !0))
              );
            }),
            o || t
              ? ((r = 'Uh oh! Please attend to the required fields above.'),
                t && o
                  ? (r += ' You have entered an invalid email address.')
                  : t && (r = 'You have entered an invalid email address.'),
                n.addClass('warning').text(r),
                e.find('button').attr('disabled', !1),
                !1)
              : (n.text(''), !0)
          );
        })
        .on('ajax:success', () =>
          n
            .removeClass('warning')
            .addClass('success')
            .text('Thank you! Your message has been sent.'),
        )
        .on(
          'ajax:error',
          (t, i) => n.addClass('warning').text(i.responseText),
          e.find('button').attr('disabled', !1),
        );
  })();

  (function() {
    let e, t, n, i, o;
    (i = $('#area optgroup').clone()),
      (e = $('#area')),
      (n = $('#subject')),
      (t = $('#state')),
      (o = function() {
        let o, r, s;
        return (
          (r = t.find(':selected').data('category')),
          (s = n.val() === '' ? null : n.val()),
          (o = i.clone()),
          r != null && o.find(`[data-category!='${r}']`).remove(),
          s != null && (o = o.filter(`.${s}`)),
          e
            .html(o)
            .prepend("<option value=''>Showing All</option>")
            .find('option[value=listening]')
            .attr('disabled', !0)
        );
      }),
      o(),
      t.on('change', () => o()),
      n.on('change', () => o());
  })();
}(jQuery));

import jQuery from 'jquery';
import 'slick-carousel';

(function module($) {
  $('.slides').slick({
    autoplay: !1,
    autoplaySpeed: 5e3,
    infinite: !0,
    pauseOnHover: !1,
    speed: 400,
    slidesToShow: 1,
    prevArrow: $('.prev-slide'),
    nextArrow: $('.next-slide'),
    cssEase: 'ease-in-out',
    dots: !0,
  });

  const videos = Array.from(document.querySelectorAll('.block.videos'));
  if (videos.length) {
    videos.map((video) => {
      const element = video.querySelector('.videos__list');
      const prev = video.querySelector('.prev-slide');
      const next = video.querySelector('.next-slide');

      $(element).slick({
        autoplay: false,
        infinite: true,
        pauseOnHover: false,
        speed: 400,
        slidesToShow: 3,
        prevArrow: $(prev),
        nextArrow: $(next),
        cssEase: 'ease-in-out',
        dots: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              slidesToShow: 1,
            },
          },
        ],
      });
    });
  }

  $('.readiness-check__checklist--slider').slick({
    autoplay: false,
    infinite: false,
    pauseOnHover: !1,
    speed: 400,
    slidesToShow: 1,
    prevArrow: $('.prev-slide'),
    nextArrow: $('.next-slide'),
    cssEase: 'ease-in-out',
    centerMode: true,
    dots: true,
    responsive: [
      {
        breakpoint: 999,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  });

  const stHeight = $('.slick-track').height();
  $('.readiness-check__checklist--slide').css('height', `${stHeight}px`);
}(jQuery));
